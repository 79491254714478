@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&family=Passion+One&family=Saira+Condensed:wght@700&family=Teko:wght@600&display=swap');

html {
    width:100%;
    margin:auto;
    direction: ltr;
    overflow-x: hidden;
    user-select: none;
    scroll-behavior: smooth;;

}
html,body,#root{
    /* background:linear-gradient(rgb(228, 155, 55, 0.484),rgba(254, 213, 118, 0.484),rgb(228, 155, 55, 0.484)); */
    background: linear-gradient(to top, rgba(228, 156, 55, 0.7),rgba(254, 213, 118, 0.7),rgba(254, 213, 118, 0.7),rgba(228, 156, 55, 0.7));
    color:rgb(255, 255, 255);
    scrollbar-width: thin;
    scrollbar-color: #BE7623 #FED576;
    margin:auto;
}
body,#root {
    background:transparent;
}
body::before{
    position:fixed;
    content: "";
    background: #d89a09;
    background-image: url(../../../Images/section2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment:fixed;
    filter:sepia(80%) opacity(50%);
    width:100%;
    height:100vh;
    z-index:-1;
}



* {
    font-family: 'Roboto', sans-serif;
    
}
button {
    font-family: 'Teko', sans-serif;
}
.header{
    display:block;
    position:fixed;
    top:0;
    width:100%;
    z-index:10;
}
/* ------- ---------- -------
/* ------- [ Loader ] -------
/* ------- ---------- ------- */

.loader {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    perspective: 800px;
    }
    
    .inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;  
    }
    
    .inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 5px solid rgb(94, 70, 11);
    }
    
    .inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 5px solid rgb(94, 70, 11);
    }
    
    .inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 5px solid rgb(94, 70, 11);
    }
    
    @keyframes rotate-one {
    0% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
    }
    
    @keyframes rotate-two {
    0% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
    }
    
    @keyframes rotate-three {
    0% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
    }
    #loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: block;
    opacity: 0.9;
    background: linear-gradient(to top, rgba(228, 156, 55, 0.7),rgba(254, 213, 118, 0.7),rgba(254, 213, 118, 0.7),rgba(228, 156, 55, 0.7));
    z-index: 99;
    text-align: center;
    }
    #loading-image {
    position: absolute;
    top: 100px;
    left: 240px;
    z-index: 100;
    }
    .loading-text {
        color:#371301;
        text-shadow: #a88940;
        transform: translate(-15%,200%);
        font-size:2rem;
        font-weight:bold;
        font-family:'Passion One', sans-serif;
    }



/* ------- ---------- ------ 
/* ------- [ Navbar ] ------ 
/* ------- ---------- ------ */
.top-navbar{
    background:rgba(248, 232, 161, 0.882);
    width:100%;
    display:block;
    position:relative;
    height:3rem;
    box-shadow:0px 1px 4px rgba(255, 246, 114, 0.438);
    margin:auto;
    text-align:center;
}
.logo{
    position:relative;
    display:block;
    filter:sepia(80%);
    transition: transform 1s;
}
.logo:hover {
    filter:sepia(40%);
    cursor:pointer;
    transform: scale(1.2);
    
}
.logo-container{
    position:absolute;
    left:0;
    width:100%;
    height:max-content;

}

.logo-container> svg {
    width: 100px;
    height: 100px;
    margin:0px -4px;

  }
.hexagon {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    stroke-width: 1;
    fill-opacity: 0.8;
    stroke-dashoffset: 0;
  }
  .AnimateHex {
    display:inline;
    animation: strokes 3s linear;
    animation-iteration-count:infinite;
    stroke-dashoffset:500;
    stroke-dasharray: 35;
    stroke-width: .15rem;
    stroke-linejoin: round;
}
  @keyframes strokes {
    to {
        stroke-dashoffset: 0;
    }
}
.logo-pic{
    top:0;
    left:0;
    margin:0;
    width:60%;
    padding:.5rem;
    
}
.navbar-elements {
    display:block;
    position: relative;
    font-family:'Teko', cursive;
    height:3rem;
    margin:auto auto;
    justify-content: center;
    align-items: center;
}
.navbar-elm {
    border-right:1px solid rgba(128, 128, 128, 0.192);
    margin:auto auto;
    display:block;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align:center;
    font-family: 'Teko', cursive;
    height:3rem;
}
.navbar-menu-link {
    display:block;
    position: relative;
    text-decoration:none;
    color:goldenrod;
    margin:auto;
    text-align:center;
    font-family: 'Teko', cursive;
    width:100%;
}
.navbar-menu-link > *{
    padding-top:.75rem;
}
.navbar-elm:hover {
    background:rgba(211, 211, 211, 0.247);
}
.navbar-elm:hover .navbar-menu-link{
    color:#371301;
}
.theme-options {
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: small;
}
.lang-toggler {
    border-radius:15px;
    border:2px groove goldenrod;
    width:max-content;
    display:flex;
    justify-content: space-between;
    align-self:center;
}
.langs-tab {
    padding:3px;
}
.langs-tab:hover {
    cursor: pointer;
}
.active-lang {
    border:1px solid lightgrey;
    background:goldenrod;
    border-radius:50%;
}

.other-lang {
    font-weight: bold;
}



/* ------- ----------- ------ */
/* ------- [ infobar ] ------ */
/* ------- ----------- ------ */

.infobar{
    background:#5A2405;
    width:100%;
    display:block;
    position:relative;
    height:2em;
    text-align:center;
}
.userInfo{
    position:relative;
    background:rgba(248, 213, 99, 0.425);
    border-radius:25px 0 0 25px;
    color:rgb(224, 224, 224);
    margin:auto;
    text-align:right;
    justify-content: center;
    align-items: center;
}
.userpic{
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    text-align:center;
    box-shadow: 0px 1px 2px #d89a09;
    margin:0 .75rem;
}
.userpic> img {
    position:relative;
    /* display:inline; */
    /* margin:auto; */
    width:1.75rem;
    /* border:2px solid rgba(130, 131, 130, 0.808); */
    border-radius: 5px;
}
.username{
    position:relative;
    display:inline;
    /* margin:auto; */
    /* text-align:center; */
}
.username:hover{
    text-decoration: underline dashed rgba(255, 255, 255, 0.384);
    text-underline-position: under;
    cursor: pointer;
    color:white;
}
.logout{
    text-align:center;
    position:relative;
    display:inline;
    margin:auto;
    font-family: 'Teko', cursive;
}
.signin{
    text-decoration: none;
    color:rgb(27, 25, 25);
    font-family: 'Roboto', sans-serif;
}
.signin:hover{
    text-decoration: underline dashed;
    text-underline-position: under;
    color:white;
}

/* ----- ------ ------- ----- */
/* ---- [ Slide Banner ] ---- */
/* ----- ------ ------- ----- */
.banner2{
    width:100%;
    background: linear-gradient(to top, rgba(228, 156, 55, 0.7),rgba(254, 213, 118, 0.7),rgba(254, 213, 118, 0.7),rgba(228, 156, 55, 0.7));
    margin-top:0;
    height:65vh;
    text-align:center;
}
.wave-svg-shape {
    fill: rgba(228, 156, 55, 0.5);
    shape-rendering:auto;
}
.banner-title {
    position:relative;
    margin:auto;
    display:inline-block;
    font-size:3rem;
    color:goldenrod;
    padding:.5rem;
    
}
.quote-text {
    color:#684d0d;
    font-size:1.8rem;
    font-family: 'Saira Condensed', sans-serif;
    letter-spacing: 2px;
    padding:0 .5rem;
    text-align:left;
}
.banner-image {
    position:relative;
    display:block;
    width:98%;
    margin:auto;
    border-radius:25px 50px;
    filter:sepia(80%) opacity(80%) drop-shadow(5px 5px 5px rgb(114, 89, 26));
    border:.5rem double goldenrod;
    border-style:hidden double hidden double;
    top:2rem;
    animation: flop 3s linear infinite;
}
@keyframes flop {
    0% {
        opacity:1;
    }
    25% {
        opacity:.8;
    }
    50% {
        opacity:5;
    }
    75% {
        opacity:.8;
    }
    100% {
        opacity:1;
    }
}
q{
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.services-circle {
    display:block;
    position:relative;
    margin:auto;
    width:12rem;
    height:12rem;
    border-radius: 50%;
    background: linear-gradient(to top, rgba(228, 156, 55, 0.4),rgba(254, 213, 118, 0.2),rgba(254, 213, 118, 0.4),rgba(228, 156, 55, 0.4));
    filter: drop-shadow(0 0 .75rem  goldenrod);
    margin-top:-8rem;
    transition:all 1s;
    transition-timing-function: ease-in-out;
}
.services-circle > * {
    display:block;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    margin:auto;
    text-decoration: none;
    color:#e9901c;
    text-shadow: 0px 0px 2px #2b2103;
    font-weight: bold;
}
.services-circle::before{
    position:absolute;
    content:"";
    height:100%;
    width:100%;
    animation: rotating 2s infinite linear;
    border:6px double rgb(238, 197, 95);
    border-style:double hidden double hidden;
    border-radius:50%;
    top:0;
    left:0;
}
.services-circle:hover{
    cursor:pointer;
    background: linear-gradient(to top, rgba(228, 156, 55, 0.6),rgba(254, 213, 118, 0.6),rgba(254, 213, 118, 0.6),rgba(228, 156, 55, 0.6));
    transform:scale(1.2,1.2)
}

@keyframes rotating {
    0% {
        transform:rotate(0deg);
    }
    50% {
        transform:rotate(180deg);
    }
    100% {
        transform:rotate(360deg);
    }
}

/* ----- ------ ------- ----- */
/* ----- [ Section(1) ] ----- */
/* ----- ------ ------- ----- */

.section-1-top svg, .section-1-bottom svg {
    fill:rgba(228,156,55,0.5);
    
}
.section-1-bottom {
    transform: rotate(180deg);
    margin: -.5px 0;
}
.section-1-mid {
    margin: -.2px auto;
    background: linear-gradient(to top, rgba(228, 156, 55, 0.5),rgba(254, 213, 118, 0.6),rgba(254, 213, 118, 0.6),rgba(228, 156, 55, 0.5));
    padding:2rem 0;
    display:flex;
    justify-content: center;
    align-items: center;
}
.sec-right, .sec-left {

    align-self: center;
    justify-content: center;
    display: flex;;
    text-align: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    width:80%;
    border-radius:0 25px 25px 0;
    background-color: #9a490638;
    margin-top:1rem;
    margin-bottom:1rem;
    box-shadow:0px 2px 4px rgba(73, 54, 4, 0.84);
    padding:1rem 0;
}


.sec-right {
    margin-left:20%;
    right:0;
    direction: rtl;
    border-radius: 25px 0 0 25px;
}
.sec-left .col-md-7, .sec-right .col-md-7 {
    display:flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    text-align: center;
}
.sec-right .col-md-7 * {
    margin-left:5%;
}
.sec-images {
    position:relative;
    display:block;
    width:60%;
    margin:0.75rem;
    text-align: center;
    border-radius:25px 50px;
    filter:sepia(80%) opacity(80%) drop-shadow(5px 5px 5px rgb(114, 89, 26));
    border:.5rem double goldenrod;
    border-style:hidden double hidden double;
    animation: flop 3s linear infinite;
}
.field-title {
    font-family: 'Teko', 'sans-serif';
}
.read-more-btn {
    background: linear-gradient(to top, rgba(228, 156, 55, 0.7),rgba(254, 213, 118, 0.7),rgba(254, 213, 118, 0.7),rgba(228, 156, 55, 0.7));
    border:1px solid goldenrod;
    filter: drop-shadow(1px 1px 3px rgb(77, 68, 47));
    border-radius: 5px;
    padding:.25rem 1rem;
    opacity: .8;
}

.read-more-btn:hover {
    opacity:1;
    cursor: pointer;
    color:white;
}

/*------------------*/
/*  More Services   */
/*------------------*/

.other-services {
    margin: .5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 1s;
}
.show-more:hover {
    cursor: pointer;
    
}
.show-more {
    height:3rem;
    display:flex;
    justify-content: center;
    align-items: center;
}
.show-more-arrow {
    position: relative;
    text-align: center;
    transition: all 1s ease-in-out;
}
.show-more-arrow.reversed {
    transform:rotate(180deg);
}
.other-serivces-block {
    display:flex;
    position:relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin:0.5rem;
    user-select: none;
    -webkit-user-drag: none;
    scroll-behavior: smooth;
    width: 0px;
    overflow: hidden;
    transition: all 2s ease-in-out;
    border:.25rem double goldenrod;
    border-style: double hidden double hidden;
    border-radius:25px 50px;
    box-shadow: 0px 2px 3px #2b2103;
    background: linear-gradient(to top, rgba(228, 156, 55, 0.7),rgba(254, 213, 118, 0.7),rgba(254, 213, 118, 0.7),rgba(228, 156, 55, 0.7));
}
.other-serivces-block.shown-section {
    width:95vw;
}



.slider-btn-click {
    transform: scale(1.1);
}
.other-services-tab {
    display:flex;
    width:95vw;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
    border-radius:15px;
    scrollbar-width: thin;
    scrollbar-color: #BE7623 #FED576;
    scroll-snap-type: proximity;
    text-shadow: 0px 1px 3px #2b2103;
    margin:0 .5rem;
    transition: all 2s ease-in-out;
    scroll-behavior: smooth;
}
.services-tabs {
    transition: all 2s ease-in-out;
    padding:.5rem .5rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    width:max-content;
    background-color: #9a490638;
    margin:0.5rem;
    user-zoom: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.services-tabs .read-more-btn {
    margin-top:.5rem;
    width:90%;
    border-radius: 25px;
    margin-bottom:-.1rem;
}
.serv-images {
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    width:28vw;
    margin:auto auto;
    text-align: center;
    border-radius:25px 50px;
    filter:sepia(80%) opacity(80%) drop-shadow(5px 5px 5px rgb(114, 89, 26));
    border:.5rem double goldenrod;
    border-style:hidden double hidden double;
    animation: flop 3s linear infinite;
    user-zoom: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}


/* --------- ------ ----------*/
/* --------- Footer ----------*/
/* --------- ------ ----------*/
.svg-shape-balls{
    fill: rgba(228, 156, 55, 0.5);
    shape-rendering:auto;
}
.fotter-top{
    transform: rotate(180deg);
    width:100%;
    z-index:1;
    margin-top:3rem;
}
.fotter-mid{
    text-align:center;
    width:100%;
    font-family: 'Roboto', sans-serif;
    margin:auto;
    padding-top:1.5rem;
    z-index:99;
    background: linear-gradient(to top, rgba(228, 156, 55, 0.7),rgba(254, 213, 118, 0.7),rgba(254, 213, 118, 0.7),rgba(228, 156, 55, 0.7));
    /* background:rgba(248, 232, 161, 0.5); */
    color:#371301;
}

.fotter-end{
    text-align:center;
    width:100%;
    /*background: linear-gradient(to top, #6C3813,#9B601E,#6C3813);*/
    background: linear-gradient(to top,rgba(228, 156, 55, 0.7),rgba(254, 213, 118, 0.7),rgba(228, 156, 55, 0.7));
    font-family: 'Roboto', sans-serif;
    font-size:.85rem;
    z-index:99;

}
.footer-img-container{
    width:100%;
    min-height:100%;
    margin:auto auto;
    align-items: center;
    justify-content: center;
    display:flex;
}
.footer-img{
    max-width:60%;
    padding:1rem;
    border-radius:15px;
    background:#fed57648;
    border:10px solid rgba(197, 191, 177, 0.152);
    box-shadow:1px 2px 3px rgb(73, 72, 72);
    filter:drop-shadow(0 0 1rem goldenrod) saturate(100%) opacity(100%);
    vertical-align:middle;
    align-self: center;
}
.footer-main-icons{
    margin-left:.25rem;
    margin:auto auto;
}
.footer-main-items{
    font-size:.8rem;
    text-align:center;
    padding-left:1rem;
    margin:auto;
}
.footer-main-items a {
    text-decoration:none;
    color:#684d0d
}
.footer-main-items a:hover {
    text-decoration:underline dashed;
    color:#a88940;
    text-underline-position: under;
}
.footer-icons{
    width:1.5rem;
    height:max-content;
    margin:auto auto;
}
.mob-icon {
    color:#371301;
    height:1.75rem;
    margin:0.25rem;
    text-align:right;
    border-radius:50%;
    padding:.25rem;
    
}
.lcl-icon {
    color:red;
    height:1.75rem;
    margin:0.25rem;
    text-align:right;
    border-radius:50%;
    padding:.25rem;
}
.wa-icon {
    margin:0.25rem;
    height:1.75rem;
    color:green;
}

.social-icons{
    font-size:2rem;
}

.social-icons{
    margin:1rem 0;
    width:2rem;
    height:2rem;
    transition: transform .5s;
    filter: grayscale(100%) opacity(70%) drop-shadow(0 0 0.15rem gray);

}

.social-icons:hover {
    transform: scale(1.25) ;
    cursor:pointer;
    filter: saturate(100%) opacity(100%) drop-shadow(0 0 0.15rem rgb(77, 166, 250));
}
.insta-icon.social-icons:hover {
    transform: scale(1.25) ;
    cursor:pointer;
    filter: saturate(100%) opacity(100%) drop-shadow(0 0 0.15rem crimson);
}
.fb-icon {
    background:rgb(41, 41, 207);
    color:white;
    border-radius:5px;
    
}
.twtr-icon {
    color:rgb(23, 233, 233);
    
}
.lnkd-icon {
    background:rgb(14, 105, 196);
    color:white;
    border-radius:5px;
}
.insta-icon {
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    color:white;
    border-radius:10px;
}
.footer-links-group{
    text-align:center;
    margin:auto;
    font-size:.85rem;
    color:#FED576;
    margin-left:-1.75rem;
}
ul {
    list-style-type:none;
}
.footer-section-title{
    color:#684d0d;
    text-shadow:1px 0px 1px goldenrod;
    border:2px solid goldenrod;
    border-radius:15px;
    padding:.25rem 1rem;
    margin:.24rem auto;
    width:max-content;
    background:#e7b241b2;
}

.footer-link> * {
    color:#997114;
    text-decoration: none;
    text-underline-position: under;
}
.footer-link> *:hover {
    color:goldenrod;
    text-decoration:underline;
    text-decoration-style: dashed;
    text-underline-position: under;
}
.map-location{
    border-radius:15px;
    border: 2px solid #FED576;
    width:95%;
    height:100px;
    margin:2rem auto;
    filter: drop-shadow(0 0 0.75rem goldenrod)
}
.fotter-end > *{
    text-decoration:none;
    color:#BE7623;
}


/* --------- ------- ----------*/
/* ------ Error Page -------*/
/* --------- ------- ----------*/

.error-page {
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height:40vh;
    margin-top:2vmax;
}
.message-box {
    width:50%;
    margin-top:2rem;
    border-radius:25px;
    border:5px groove goldenrod;
    background:#99711485;
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.message-head {
    padding:.75em;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: 'Teko', sans-serif;
    color:#d4c37d;
    text-shadow: 1px 2px 2px #684d0d;
    animation: zoomword 2s linear infinite;
    transition: zoomword 2s;
}
.message-body, .message-body * {
    padding:.25rem;
    justify-content: center;
    font-family:'Teko', sans-serif;
    font-size:1.5em;
}

@keyframes zoomword {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1.2);
    }
    75% {
        transform: scale(1.1)
    }
    100% {
        transform: scale(1);
    }


}


/* --------- ------- ----------*/
/* ------ Media Queries -------*/
/* --------- ------- ----------*/
@media screen and (max-width: 600px) {
    .top-navbar{
        bottom:0;
        position:fixed;
        display:block;
        box-shadow:1px -1px 5px gray;
        width:100%;
    }
    .infobar{
        height:2rem;
        color:white;
        margin:auto;

    }
    #root {
        margin-top:1.62rem;
        margin-bottom:3rem;
    }

    .logo-pic{
        width:60%;
    }
    .logo-container{
        bottom:0;
    }
    #Hex2 {
        width:40.5px;
        height:40.5px;
        margin-bottom:3px;
    }
    .navbar-menu-link{
        text-align:center;
        width:100%;
        left:0;
        margin:auto;
        display:flex;
        justify-content:center;
        align-self: center;;
    }
    .text-word {
        font-size:2rem;
    }
    .banner2{
        margin-top:.0;
    }
    .banner-image {
        top:0;
    }
    .services-circle {
        width:6.5rem;
        height:6.5rem;
        margin-top:-3rem;
    }
    .footer-section-title{
        margin-top:.75rem;
    }
    .footer-top {
        margin-bottom: -1px;
    }
    .error-page {
        justify-content: center;
    }
    .message-box {
        width:95%;
    }
    .message-body, .message-body * {
        font-size:1.2em;
    }
    .sec-right, .sec-left {
        width:95%;
        margin:1rem auto;
        border-radius: 25px;
    }
    .sec-right .col-md-7 > *{
        margin:auto auto;
    }
    .show-more-arrow {
        left:48%;
    }
    .other-services-tab {
        overflow: scroll;

    }
    .serv-images {
        width:50vw;
    }
}
